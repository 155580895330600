/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLoginStateFailed } from '../redux/userSlice';
const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	// headers: {
	//   'Content-type': 'application/json',
	// },
	paramsSerializer: {
		serialize: (params) => {
			return new URLSearchParams(params).toString();
		},
	},
});

const AxiosInterceptor = ({ children }) => {
	const navigate = useNavigate();
	const [isSet, setIsSet] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		const interceptor = axiosClient.interceptors.request.use(async (config) => {
			const token = await window.localStorage.getItem('facit_authUsername');

			config.headers.Authorization = `Bearer ${token}`;
			return config;
		});

		const interceptor2 = axiosClient.interceptors.response.use(
			(response) => {
				if (response && response.data) {
					return response;
				}
				return response;
			},
			(error) => {
				if (error.response.status === 401) {
					navigate('/auth-pages/login');
					// window.localStorage.removeItem('accessToken');
					dispatch(getLoginStateFailed());
				} else if (error.code == 'ERR_NETWORK') {
					navigate('/auth-pages/login');
				}
				throw error;
			},
		);
		setIsSet(true);
		return () => axiosClient.interceptors.response.eject(interceptor, interceptor2);
	}, []);

	return isSet && children;
};
export const sendGet = (url, params) => axiosClient.get(url, { params });
export const sendPost = (url, params, queryParams) =>
	axiosClient.post(url, params, { params: queryParams });
export const sendPut = (url, params) => axiosClient.put(url, params);
export const sendPatch = (url, params) => axiosClient.patch(url, params);
export const sendDelete = (url, params) => axiosClient.delete(url, params);
export default axiosClient;
export { AxiosInterceptor };
