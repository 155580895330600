export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
};
export const extraMenu = {
	settingpage: {
		id: 'setting',
		text: 'Cài đặt tài khoản',
		path: 'setting',
		icon: 'Settings',
		subMenu: null,
	},
	withdraw: {
		id: 'withdraw',
		text: 'Rút tiền',
		path: '/rut-tien',
		icon: 'Payments',
		subMenu: null,
	},
	refer: {
		id: 'refer',
		text: 'Giới thiệu bạn bè',
		path: 'refercode',
		icon: 'AdsClick',
		subMenu: null,
	},
	help: {
		id: 'help',
		text: 'Hướng dẫn',
		path: 'help',
		icon: 'Help',
		subMenu: null,
	},
	faq: {
		id: 'faq',
		text: 'Câu hỏi thường gặp',
		path: 'faq',
		icon: 'QuestionAnswer',
		subMenu: null,
	},
};
export const mainMenu = {
	home: {
		id: 'home',
		text: 'Tổng quan',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	traffic: {
		id: 'traffic',
		text: 'Nhiệm vụ Traffics',
		path: '/nhiem-vu-traffic',
		icon: 'Traffic',
		subMenu: null,
	},
	backlink: {
		id: 'backlink',
		text: 'Nhiệm vụ Backlink',
		path: '/nhiem-vu-backlink',
		icon: 'GppGood',
		subMenu: null,
	},
	direct: {
		id: 'direct',
		text: 'Nhiệm vụ Direct',
		path: '/traffic-direct',
		icon: 'Directions',
		subMenu: null,
	},
	review: {
		id: 'review',
		text: 'Nhiệm vụ Reviews',
		path: '/nhiem-vu-review',
		icon: 'Map',
		subMenu: null,
	},
	mapReview: {
		id: 'mapReview',
		text: 'Nhiệm vụ SEO Review',
		path: '/nhiem-vu-map-review',
		icon: 'Map',
		subMenu: null,
	},
	// withdraw: {
	// 	id: 'withdraw',
	// 	text: 'Rút tiền',
	// 	path: '/rut-tien',
	// 	icon: 'Payments',
	// 	subMenu: null,
	// },
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login/*',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up/*',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};
export const otherMenu = {
	changepass: {
		id: 'changepass',
		text: 'Login',
		path: 'change-pass/*',
	},
	verify: {
		id: 'login',
		text: 'Login',
		path: 'verify-user/*',
	},
	detail: {
		id: 'detail',
		text: 'detail',
		path: 'nhiem-vu-review/detail/*',
	},
	instruction: {
		id: 'instruction',
		text: 'Hướng dẫn',
		path: 'help/instruction',
	},
};
export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
