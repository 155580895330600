/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState:{
        userProfile:{
            currentUser: null,
            currentUserGoogle: null,
            isFetching: false,
            error: false,
            isLogin: false
        },

    },
    reducers:{
        getProfileStart: (state) =>{
            state.userProfile.isFetching = true;
        },
        getProfileSuccess: (state,action) => {
            state.userProfile.isFetching = false;
            state.userProfile.currentUser = action.payload;
            state.isLogin = true;
        },
        getProfileFailed: (state) =>{
            state.userProfile.isFetching = false;
            state.userProfile.error = true;
            state.userProfile.isLogin = false;
        },
        getLoginStateSuccess: (state) => {
            state.userProfile.isLogin = true;
        },
        getLoginStateFailed: (state) => {
            state.userProfile.isLogin = false;
        },
        getUserGoogleSuccess: (state,action) => {
            state.userProfile.currentUserGoogle = action.payload;
        }
    }
})
export const {
    getProfileStart,
    getProfileSuccess,
    getProfileFailed,
    getLoginStateFailed,
    getLoginStateSuccess,
    getUserGoogleSuccess,

} = userSlice.actions;
export default userSlice.reducer;