/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useState } from "react";
import PropTypes from 'prop-types';
import styles from './loading.module.css'
import Banner from '../assets/img/review/banner.jpg'
import Icon from "../components/icon/Icon";
import { useMedia } from "react-use";
import Button from "../components/bootstrap/Button";
import Spinner from "../components/bootstrap/Spinner";
export const LoadingContext = createContext();
export const useLoading = () => useContext(LoadingContext);

const Loading = () => {
    return (
        <>
            <div className={styles.loading_container}>
                <Button className="d-flex align-items-center" color='info' isLight>
                    <Spinner isSmall inButton isGrow size='4rem' />
                    Đang tải...
                </Button>
            </div>
        </>
    )
}

export const LoadingProvider = (props) => {
    const [loading, setLoading] = useState(false);
    return (
        <LoadingContext.Provider
            value={{
                loading: loading,
                show: () => setLoading(true),
                hide: () => setLoading(false)
            }}>
            {loading && <Loading />}
            {props.children}
        </LoadingContext.Provider>
    );
}
LoadingProvider.propTypes = {
    children: PropTypes.node
};