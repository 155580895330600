import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { getUser } from '../api/authApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginStateFailed, getLoginStateSuccess, getProfileSuccess } from '../redux/userSlice';
import AuthContext from '../contexts/authContext';
import { demoPagesMenu } from '../menu';
import showNotification from '../components/extras/showNotification';
const App = () => {
	getOS();

	// const fetchHistoryTrafficJob = async () => {
	// 	const input = {
	// 		job_name: 'traffics',
	// 		page: page,
	// 		perPage: perpage,
	// 	};
	// 	try {
	// 		const res = await getJobHistory(input);
	// 		if (res.status == 200) {
	// 			const resData = res.data.data;
	// 			console.log(resData);
	// 		}
	// 	} catch (err) {
	// 		throw err;
	// 	}
	// };
	// fetchHistoryTrafficJob();
	/**
	 * Dark Mode
	 */
	const { user, setUser } = useContext(AuthContext);
	const dispatch = useDispatch();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/auth-pages/login'), [navigate]);
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});
	// getMe
	// const getMe = async () => {
	// 	try {
	// 		const res = await getUser();
	// 		if (res.status === 200) {
	// 			dispatch(getProfileSuccess(res.data.data));
	// 			dispatch(getLoginStateSuccess());

	// 		}
	// 	} catch (err) {
	// 		if (err.response.status === 401) {
	// 			setUser('');
	// 			showNotification('', 'Bạn cần đăng nhập lại', 'danger');
	// 			dispatch(getLoginStateFailed());
	// 		}
	// 	}
	// };
	// useEffect(() => {
	// 	getMe();
	// }, [user]);
	// useEffect(() => {
	// 	if (user === '') {
	// 		navigate(`../${demoPagesMenu.login.path}`);
	// 	}
	// 	return () => { };
	// }, [user]);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<AsideRoutes />
				<Wrapper />
				<Portal id='portal-notification'>
					<ReactNotifications />
				</Portal>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
