/* eslint-disable prettier/prettier */
import classNames from 'classnames'
import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import { getUser, logout } from '../../../api/authApi'
import USERS from '../../../common/data/userDummyData'
import Avatar from '../../../components/Avatar'
import Button from '../../../components/bootstrap/Button'
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown'
import Popovers from '../../../components/bootstrap/Popovers'
import showNotification from '../../../components/extras/showNotification'
import Icon from '../../../components/icon/Icon'
import AuthContext from '../../../contexts/authContext'
import useDarkMode from '../../../hooks/useDarkMode'
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header'
import { demoPagesMenu } from '../../../menu'
import { getLoginStateFailed, getLoginStateSuccess, getProfileSuccess } from '../../../redux/userSlice'

const CoreHeader = () => {
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const userProfile = useSelector((state) => state.user.userProfile);
    const userCurrent = userProfile.currentUser;
    const isWide = useMedia('(min-width: 768px)');
    const dispatch = useDispatch()
    const styledBtn = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };
    const navigate = useNavigate()
    const { user, setUser } = useContext(AuthContext);

    const handleOnClick = useCallback(() => navigate('/auth-pages/login'), [navigate])
    const getMe = async () => {
        try {
            const res = await getUser();
            if (res.status === 200) {
                dispatch(getProfileSuccess(res.data.data));
                dispatch(getLoginStateSuccess());

            }
        } catch (err) {
            if (err.response.status === 401) {
                setUser('');
                // showNotification('', 'Bạn cần đăng nhập lại', 'danger');
                dispatch(getLoginStateFailed());
            }
        }
    };
    useEffect(() => {
        getMe();
    }, [user]);
    useEffect(() => {
        if (user === '') {
            navigate(`../${demoPagesMenu.login.path}`);
        }
        return () => { };
    }, [user]);
    const handleLogout = async () => {
        try {
            const res = await logout();
            if (res.status == 200) {
                handleOnClick();
                window.localStorage.removeItem('facit_authUsername');
                dispatch(getLoginStateFailed());
            }
        }
        catch (err) {
            throw err;
        }

    }
    return (
        <Header>
            <HeaderLeft>
                <div className="d-none"></div>
            </HeaderLeft>
            <HeaderRight>
                <div className="d-flex flex-row align-items-center">
                    {/* <div className='col-auto ms-2'>
                        <Popovers trigger='hover' desc='Dark / Light mode'>
                            <Button
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...styledBtn}
                                onClick={() => setDarkModeStatus(!darkModeStatus)}
                                className='btn-only-icon'
                                data-tour='dark-mode'>
                                <Icon
                                    icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
                                    color={darkModeStatus ? 'info' : 'warning'}
                                    className='btn-icon'
                                />
                            </Button>
                        </Popovers>
                    </div> */}
                    {isWide ? <Dropdown>
                        <DropdownToggle>
                            {userCurrent?.social?.avatar == null ? <Avatar
                                size={48}
                                src={USERS.JOHN.src}
                                color={USERS.JOHN.color}
                            /> : <Avatar
                                size={48}
                                src={userCurrent?.social?.avatar}></Avatar>}
                        </DropdownToggle>
                        <DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
                            <div className='row g-0'>
                                <div
                                    className={classNames('col-12 p-4 d-flex justify-content-center fw-bold fs-5 text-info border-bottom border-info ', {
                                        'bg-l25-info': !darkModeStatus,
                                        'bg-lo10-info text-white': darkModeStatus
                                    })}>
                                    Thiết lập nhanh
                                </div>
                                <Link className='text-decoration-none' to='/setting'>
                                    <div
                                        className={classNames('col-12 p-4 transition-base cursor-pointer  border-bottom', {
                                            'bg-light-hover': !darkModeStatus,
                                            'bg-dark-hover': darkModeStatus,
                                        })}>
                                        <div className='d-flex align-items-center'>
                                            <div className="d-flex align-items-center">
                                                <Icon icon='ManageAccounts' size='3x' color='primary' />
                                                <span className={classNames({
                                                    'text-white': darkModeStatus,
                                                    'text-dark': !darkModeStatus,
                                                }, 'text-decoration-none text-dark fw-bold ms-3')}>Thông tin cá nhân</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div
                                    onClick={handleLogout}
                                    className={classNames('col-12 p-4 transition-base cursor-pointer  border-bottom', {
                                        'bg-light-hover': !darkModeStatus,
                                        'bg-dark-hover': darkModeStatus,
                                    })}>
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex align-items-center">
                                            <Icon icon='Logout' size='3x' color='danger' />
                                            <span className='fw-bold ms-3'>Đăng xuất</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown> :
                        <>
                            <div
                                onClick={handleLogout}
                                className={classNames('col-12 p-4 transition-base cursor-pointer  border-bottom', {
                                    'bg-light-hover': !darkModeStatus,
                                    'bg-dark-hover': darkModeStatus,
                                })}>
                                <div className='d-flex align-items-center'>
                                    <div className="d-flex align-items-center">
                                        <Icon icon='Logout' size='3x' color='danger' />
                                        <span className='fw-bold ms-3'>Đăng xuất</span>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </HeaderRight>
        </Header>
    )
}

export default CoreHeader