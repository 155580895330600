import React, { lazy } from 'react';
import {
	dashboardPagesMenu,
	demoPagesMenu,
	extraMenu,
	mainMenu,
	otherMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	HOME: lazy(() => import('../pages/FamerPages/Home/Home')),
	WITHDRAW: lazy(() => import('../pages/FamerPages/Withdraw/WithdrawPage')),
	TRAFFIC: lazy(() => import('../pages/FamerPages/Traffics/Traffic')),
	REVIEW: lazy(() => import('../pages/FamerPages/Review/Review')),
	MAPREVIEW: lazy(() => import('../pages/FamerPages/MapReview/MapReview')),
	DIRECT: lazy(() => import('../pages/FamerPages/TrafficDirect/TrafficDirect')),
	BACKLINK: lazy(() => import('../pages/FamerPages/BackLink/BackLink')),
};
const EXTRA = {
	SETTING: lazy(() => import('../pages/FamerPages/Setting/SettingPage')),
	HELP: lazy(() => import('../pages/FamerPages/Help/Help')),
	REFER: lazy(() => import('../pages/FamerPages/ReferPages/ReferPages')),
	QUESTION: lazy(() => import('../pages/FamerPages/Question/Question')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
const OTHER = {
	VERIFY: lazy(() => import('../pages/FamerPages/VerifyUser/VerifyUser')),
	DETAIL: lazy(() => import('../pages/FamerPages/Review/Detail')),
	CHANGEPASS: lazy(() => import('../pages/FamerPages/ChangePass/ChangePass')),
	INSTRUCTION: lazy(() => import('../pages/FamerPages/Instructions/Instructions')),
};
const presentation = [
	/**
	 * Landing
	 */
	{
		path: mainMenu.backlink.path,
		element: <LANDING.BACKLINK />,
	},
	{
		path: otherMenu.changepass.path,
		element: <OTHER.CHANGEPASS />,
	},
	{
		path: otherMenu.verify.path,
		element: <OTHER.VERIFY />,
	},
	{
		path: otherMenu.detail.path,
		element: <OTHER.DETAIL />,
	},
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: mainMenu.home.path,
		element: <LANDING.HOME />,
	},
	{
		path: mainMenu.direct.path,
		element: <LANDING.DIRECT />,
	},
	{
		path: extraMenu.faq.path,
		element: <EXTRA.QUESTION />,
	},
	{
		path: otherMenu.instruction.path,
		element: <OTHER.INSTRUCTION />,
	},
	{
		path: extraMenu.withdraw.path,
		element: <LANDING.WITHDRAW />,
	},
	{
		path: mainMenu.traffic.path,
		element: <LANDING.TRAFFIC />,
	},
	{
		path: mainMenu.review.path,
		element: <LANDING.REVIEW />,
	},
	{
		path: mainMenu.mapReview.path,
		element: <LANDING.MAPREVIEW />,
	},
	{
		path: extraMenu.settingpage.path,
		element: <EXTRA.SETTING />,
	},
	{
		path: extraMenu.help.path,
		element: <EXTRA.HELP />,
	},
	{
		path: extraMenu.refer.path,
		element: <EXTRA.REFER />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;
