/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import styles from './login.module.css';
import Icon from '../../../components/icon/Icon';
import { login, register, resetPassword } from '../../../api/authApi';
import showNotification from '../../../components/extras/showNotification';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginStateSuccess, getProfileSuccess } from '../../../redux/userSlice';
import { mainMenu } from '../../../menu';
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const [verifyState, setVerifyState] = useState(false);
	const [signInPassword, setSignInPassword] = useState(false);
	const [singUpStatus, setSingUpStatus] = useState(!!isSignUp);
	const [showPassword, setShowPassword] = useState(false);
	const [pasteRef, setPasteRef] = useState(false);
	const [showPasswordSignUp, setShowPasswordSignUp] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const userProfile = useSelector((state) => state.user.userProfile);
	const authState = userProfile.isLogin;
	const [referCode,setReferCode] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const [params, setParams] = useSearchParams();
	const usernameCheck = (username) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username, password) => {
		return getUserDataWithUsername(username).password === password;
	};
	useEffect(() => {
        const a = new URLSearchParams(window.location.pathname);
        const b = Object.fromEntries(a.entries());
        const c = Object.keys(b);
        const d = c[0].split('/')
        if(d.includes('sign-up')){
			if(d[d.indexOf('sign-up') + 1] && d[d.indexOf('sign-up') + 1].length !==0) {
				setReferCode(d[d.indexOf('sign-up') + 1]);
			}
		};
    }, []);
	const handleSignUp = async (values) => {
		const data = {
			email: formikSignUp.values.signupEmail,
			password: formikSignUp.values.signupPassword,
			password_confirmation: formikSignUp.values.signupPasswordConfirm,
			name: formikSignUp.values.signupName,
			ref_link: formikSignUp.values.refer,
			phone: formikSignUp.values.signupPhone,
		};

		try {
			const res = await register(data);
			if (res.status === 200) {
				setSingUpStatus(false);
				showNotification('', 'Vào Email để xác nhận tài khoản', 'success');
			}
		} catch (err) {
			showNotification('', err.response.data.error_msg, 'danger');
		}
	};
	const handleSubmit = async () => {
		const loginData = {
			email: formik.values.loginUsername,
			password: formik.values.loginPassword,
		};
		try {
			const res = await login(loginData);
			if (res.status === 200) {
				handleOnClick();
				showNotification('', 'Đăng nhập thành công', 'success');
				localStorage.setItem('facit_authUsername', res.data.data.token);
				setUser(res.data.data.token);
				dispatch(getProfileSuccess(res.data.data));
				dispatch(getLoginStateSuccess());
			}
		} catch (err) {
			showNotification('', err.response.data.error_msg, 'danger');
		}
	};

	const formikSignUp = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupEmail: '',
			signupName: '',
			signupPassword: '',
			signupPasswordConfirm: '',
			signupPhone: '',
			// refer: params.get('ref') === null ? '' : params.get('ref'),
			refer: referCode,
		},
		validate: (values) => {
			const errors = {};

			if (!values.signupEmail) {
				errors['signupEmail'] = 'Bắt buộc';
			}

			if (!values.signupName) {
				errors['signupName'] = 'Bắt buộc';
			}

			if (!values.signupPassword) {
				errors['signupPassword'] = 'Bắt buộc';
			}
			if (!values.signupPassword) {
				errors['signupPhone'] = 'Bắt buộc';
			}
			if (!values.signupPasswordConfirm) {
				errors['signupPasswordConfirm'] = 'Bắt buộc';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSignUp(values);
		},
	});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Bắt buộc';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Bắt buộc';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});
	// useEffect(() => {

	// }, []);

	const [isLoading, setIsLoading] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const toggleShowPasswordSignUp = () => {
		setShowPasswordSignUp(!showPasswordSignUp);
	};
	const toggleShowPasswordConfirm = () => {
		setShowPasswordConfirm(!showPasswordConfirm);
	};
	const handleResendPassword = async () => {
		const input = {
			email: formik.values.resendPassword,
		};
		try {
			const res = await resetPassword(input);
			if (res.status === 200) {
				showNotification('', 'Đã gửi lại mã xác thực về email', 'success');
			}
		} catch (err) {
			showNotification('', err.response.data.error_msg, 'danger');
		}
	};
	return authState ? (
		<Navigate to={mainMenu.home.path} />
	) : (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									{/* <Link
								to='/'
								className={classNames(
									'text-decoration-none  fw-bold display-2',
									{
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									},
								)}>
							</Link> */}
									<Logo width={200} />
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />
								<div className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signupEmail'
													isFloating
													label='Email'
													className='mb-3'>
													<Input
														type='email'
														autoComplete='email'
														value={formikSignUp.values.signupEmail}
														isTouched={formikSignUp.touched.signupEmail}
														invalidFeedback={
															formikSignUp.errors.signupEmail
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signupName'
													isFloating
													label='Tên'
													className='mb-3'>
													<Input
														autoComplete='given-name'
														value={formikSignUp.values.signupName}
														isTouched={formikSignUp.touched.signupName}
														invalidFeedback={
															formikSignUp.errors.signupName
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signupPhone'
													isFloating
													label='Số điện thoại'
													className='mb-3'>
													<Input
														autoComplete='given-name'
														value={formikSignUp.values.signupPhone}
														isTouched={formikSignUp.touched.signupPhone}
														invalidFeedback={
															formikSignUp.errors.signupPhone
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<div className={styles.pwd__input}>
													<FormGroup
														id='signupPassword'
														isFloating
														label='Mật khẩu'
														className='mb-3'>
														<Input
															autoComplete='your password'
															type={
																showPasswordSignUp
																	? 'text'
																	: 'password'
															}
															value={
																formikSignUp.values.signupPassword
															}
															isTouched={
																formikSignUp.touched.signupPassword
															}
															invalidFeedback={
																formikSignUp.errors.signupPassword
															}
															isValid={formikSignUp.isValid}
															onChange={formikSignUp.handleChange}
															onBlur={formikSignUp.handleBlur}
															onFocus={() => {
																formikSignUp.setErrors({});
															}}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPasswordSignUp}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											<div className='col-12'>
												<div className={styles.pwd__input}>
													<FormGroup
														id='signupPasswordConfirm'
														isFloating
														label='Nhập lại mật khẩu'
														className='mb-3'>
														<Input
															type={
																showPasswordConfirm
																	? 'text'
																	: 'password'
															}
															value={
																formikSignUp.values
																	.signupPasswordConfirm
															}
															isTouched={
																formikSignUp.touched
																	.signupPasswordConfirm
															}
															invalidFeedback={
																formikSignUp.errors
																	.signupPasswordConfirm
															}
															isValid={formikSignUp.isValid}
															onChange={formikSignUp.handleChange}
															onBlur={formikSignUp.handleBlur}
															onFocus={() => {
																formikSignUp.setErrors({});
															}}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPasswordConfirm}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											<div className='col-12'>
												<FormGroup id='refer' isFloating label='Refer Code'>
													<Input
														id='refer'
														value={formikSignUp.values.refer}
														onChange={formikSignUp.handleChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={formikSignUp.handleSubmit}>
													Đăng ký
												</Button>
											</div>
										</>
									) : verifyState ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='resendPassword'
													isFloating
													label='Email'
													className='mb-3'>
													<Input
														value={formik.values.resendPassword}
														onChange={formik.handleChange}
													/>
												</FormGroup>
											</div>
											<div
												onClick={() => {
													setVerifyState(false);
												}}
												className='text-dark fw-bold text-center'
												style={{
													marginTop: '-10px',
													marginBottom: '-10px',
													cursor: 'pointer',
												}}>
												Đăng nhập tại đây
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={handleResendPassword}>
													Gửi lại mã
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Email hoặc tên đăng nhập'
													className='mb-3'>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												<div className={styles.pwd__input}>
													<FormGroup
														id='loginPassword'
														isFloating
														label='Mật khẩu'
														className='mb-4'>
														<Input
															type={
																showPassword ? 'text' : 'password'
															}
															autoComplete='current-password'
															value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															validFeedback='Looks good!'
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPassword}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											<div
												onClick={() => {
													setVerifyState(true);
												}}
												className='text-dark fw-bold text-center'
												style={{
													marginTop: '-10px',
													marginBottom: '-10px',
													cursor: 'pointer',
												}}>
												Bạn quên mật khẩu?
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={formik.handleSubmit}>
													Đăng nhập
												</Button>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
								<>
									<div className='col-12 mt-3 text-center text-muted'>
										OR
									</div>
									<div className='col-12 mt-3'>
										<Button
											isOutline
											color={darkModeStatus ? 'light' : 'dark'}
											className={classNames('w-100 py-3', {
												'border-light': !darkModeStatus,
												'border-dark': darkModeStatus,
											})}
											icon='CustomApple'
											onClick={handleOnClick}>
											Sign in with Apple
										</Button>
									</div>
									<div className='col-12'>
										<Button
											isOutline
											color={darkModeStatus ? 'light' : 'dark'}
											className={classNames('w-100 py-3', {
												'border-light': !darkModeStatus,
												'border-dark': darkModeStatus,
											})}
											icon='CustomGoogle'
											onClick={handleOnClick}>
											Continue with Google
										</Button>
									</div>
								</>
							)} */}
									{/* END :: Social Login */}
								</div>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
